<template>
  <div style="width: 100%; height: 100%;" class="weather-container">
    <!-- <div :id="chartId" style="width: 100%; height: 100%; margin: auto" /> -->
    <el-descriptions 
      class="descriptions-content" 
      :column="2"  
      border
      :popper-append-to-body="false"
      :title="'设备名称: '+ weatherData.deviceName"
    >
    <el-descriptions-item 
      v-for="(item, index) in descriptionsPropList"
      :key="index"
    >
      <template slot="label">
        <i :class="['iconfont',item.icon]"></i>
        {{item.label}}
      </template>
      <div style="height: 100%; width: 100%; text-align: center" :title="weatherData[item.prop]">{{weatherData[item.prop]}}</div>
    </el-descriptions-item>
  </el-descriptions>
  </div>
</template>

<script>
import echarts from "echarts";
import theme from "./chartTheme";
import { mapState } from "vuex";
import {parseWindDirection, parseWindLevel} from "@/utils/js/formatterWeather.js"
export default {
  name: "weatherRadarChart",
  data() {
    return {
      chartId: "weather-radar-chart",
      weatherData: {
          deviceName: "--",
          temperature: "--",
          humidity: "--",
          windSpeed: "--",
          windDirection: "--",
          rainFall: "--",
          pressure: "--",
          light: "--",
        },
      currentIndex: 0,
      // radarChart: "",

      descriptionsPropList: [{
        prop: "temperature",
        label: "温度",
        icon: "icon-wendu"
      },{
        prop: "humidity",
        label: "湿度",
        icon: "icon-shidu"
      },{
        prop: "windSpeed",
        label: "风速",
        icon: "icon-fengli"
      },{
        prop: "windDirection",
        label: "风向",
        icon: "icon-fengxiang"
      },{
        prop: "rainFall",
        label: "雨量",
        icon: "icon-jiangyuliang"
      },{
        prop: "pressure",
        label: "气压",
        icon: "icon-qiya"
      },{
        prop: "light",
        label: "光照",
        icon: "icon-guangzhao"
      }]
    };
  },
  methods: {
    // initChart(name, xData, yData) {
      
    //   this.radarChart = echarts.init(document.getElementById(this.chartId), "theme");
       
    //   this.radarChart.setOption(this.radarOption);

    //   //随着屏幕大小调节图表
    //   window.addEventListener("resize", () => {
    //     this.radarChart.resize();
    //   });
    // },

    /*
     * @Date 2022/03/23 15:13:08
     * 提示框内容
     */
    // radarTootip() {
    //     var htmlStr = "<div>";
    //     htmlStr += this.weatherData.deviceName + "<br/>"; //x轴的名称
    //     htmlStr += "<ul>"
    //     htmlStr = htmlStr + "<li>温度：" + this.weatherData.temperature + "°C" + "</li>";
    //     htmlStr = htmlStr + "<li>湿度：" + this.weatherData.humidity + "%" + "</li>";
    //     htmlStr = htmlStr + "<li>大气压强：" + this.weatherData.pressure + "hpa" + "</li>";
    //     htmlStr = htmlStr + "<li>风速：" + parseWindLevel(this.weatherData.windSpeed) + "级" + "</li>";
    //     htmlStr = htmlStr + "<li>风向：" + parseWindDirection(this.weatherData.windDirection) + "风" + "</li>";
    //     htmlStr = htmlStr + "<li>十分钟降雨量：" + this.weatherData.rainFall + "mm" + "</li>";
    //     htmlStr = htmlStr + "<li>光照强度: " + this.weatherData.light + "klux" + "</li>";
    //     htmlStr += "</ul>"

    //     htmlStr += "</div>";

    //     return htmlStr;
          
    // }

   
  },
  mounted() {
    // echarts.registerTheme("theme", theme);
    // this.initChart();

    setInterval(() => {
      // console.log(this.weatherStationDataList, this.weatherData);
      if(!this.weatherStationDataList || this.weatherStationDataList.length==0){
        this.weatherData = {
          deviceName: "--",
          deviceMac: "--",
          temperature: "--",
          humidity: "--",
          windSpeed: "--",
          windDirection: "--",
          rainFall: "--",
          pressure: "--",
          light: "--",
        }
      }else {
        let length = this.weatherStationDataList.length;
        this.weatherData = this.weatherStationDataList[this.currentIndex%length];
        this.currentIndex += 1;
        // this.radarChart.setOption(this.radarOption);
      }
    }, 10000);
  },

  computed: {

    /*
     * @Date 2022/03/17 08:59:07
     * 从vuex中取出气象数据
     */
    ...mapState(["weatherStationDataList"]),

    // radarOption: function() {
    //   // 异步函数里面调用，需要将this转换一下
    //   let that = this;
    //   return {
    //     title: {
    //       text: "动态气象",
    //       show: false,
    //       textStyle: {
    //         fontSize: 15,
    //         fontStyle: "normal",
    //         color: "#FFF"
    //       }
    //     },
    //     legend: {
    //       show: false
    //     },
    //     tooltip: {
    //       trigger: "item",
    //       position: "right",
    //       formatter: that.radarTootip(),
    //     },
    //     radar: {
    //       // shape: 'circle',
    //       indicator: that.radarIndicator,
    //       // 使用的是 4.9 版本 echarts，旧版本的是name，5.0 以后的版本为 nameAxis 属性
    //       name: {
    //         color: "#FFF"
    //       }
    //     },
    //     series: [
    //       {
    //         type: "radar",
    //         areaStyle: {
    //           normal: {
    //             shadowBlur: 13,
    //             shadowColor: "rgba(0,0,0,.2)",
    //             shadowOffsetX: 0,
    //             shadowOffsetY: 10,
    //             opacity: 1
    //           }
    //         },
    //         data: that.radarData
    //       }
    //     ]
    //   };
    // },

    /*
     * @Date 2022/03/17 08:59:43
     * 雷达图的坐标轴
     */
    // radarIndicator: function() {
    //   let indicators = [];
    //   if(this.weatherData) {
    //     let keys = Object.keys(this.weatherData);
    //     for(let i=0; i<keys.length; i++) {
    //        let key = keys[i];
    //        switch(key) {
    //          case "temperature":{
    //            indicators[0] = {name: "温度", max: 55};
    //            break;
    //          }
    //          case "humidity": {
    //            indicators[1]= {name: "湿度", max: 100};
    //            break;
    //          }
    //          case "windSpeed": {
    //            indicators[2] = {name: "风速", max: 60};
    //            break;
    //          }
    //          case "windDirection": {
    //            indicators[3] = {name: "风向", max: 360};
    //            break;
    //          }
    //          case "rainFall": {
    //            indicators[4] = {name: "降雨", max: 200};
    //            break;
    //          }
    //           case "pressure": {
    //             indicators[5] = {name: "压强", max: 2000};
    //             break;
    //           }
    //         case "light": {
    //             indicators[6] =  {name: "光照", max: 100};
    //             break;
    //         }
    //        }
    //     }
    //   }

    //   return indicators;
    // },

    /*
     * @Date 2022/03/17 09:54:27
     * 气象数据集
     */
    // radarData: function() {
    //   let data = [];
    //   if(this.weatherData) {
    //      let keys = Object.keys(this.weatherData);
    //       for(let i=0; i<keys.length; i++) {
    //         let key = keys[i];
    //         switch(key) {
    //          case "temperature":{
    //            data[0] = (this.weatherData[key]);
    //            break;
    //          }
    //          case "humidity": {
    //            data[1] = (this.weatherData[key]);
    //            break;
    //          }
    //          case "windSpeed": {
    //            data[2] = (this.weatherData[key]);
    //            break;
    //          }
    //          case "windDirection": {
    //            data[3] = (this.weatherData[key]);
    //            break;
    //          }
    //          case "rainFall": {
    //            data[4] = (this.weatherData[key]);
    //            break;
    //          }
    //           case "pressure": {
    //             data[5] = (this.weatherData[key]);
    //             break;
    //           }
    //           case "light": {
    //             data[6] = (this.weatherData[key]);
    //             break;
    //           }
    //        }
    //       }
    //   }
    //   return [{name: this.weatherData.stationName, value: data}];
    // },

    
  },

  
};
</script>

<style lang="scss" scoped>
  .weather-container {
    overflow: auto;
    height: 100%;

    /deep/ .descriptions-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      border: none;
      margin: 0 !important;

      .el-descriptions__header {
        align-self: flex-start;

        .el-descriptions__title {
          color: white;
          font-size: 14px;
        }
      }

      .el-descriptions__body {
        width: 100%;
        flex: 1;

        .el-descriptions__table {
          height: 100%;

          th {
            width: 75px !important;
            padding: 6px !important;
          }

          td {
            padding: 6px 2px !important;
          }
        }
      }
    }
  }
</style>

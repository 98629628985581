// echarts 水球图 配置
export const LiquidOption =  {
    title: {
      text: "0",
      textStyle: {
        fontSize: 12,
        fontFamily: "Microsoft Yahei",
        fontWeight: "normal",
        color: "rgb(11,43,64)",
        rich: {
          a: {
            fontSize: 14,
          },
        },
      },
      x: "center",
      y: "30%",
    },
    series: [
      {
        type: "liquidFill",
        radius: "80%",
        center: ["50%", "50%"],
        //  shape: 'roundRect',
        data: [0.75, 0.75, 0.75],
        backgroundStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "rgb(0,255,190)",
              },
              {
                offset: 0.5,
                color: "rgb(0, 0, 0)",
              },
              {
                offset: 0,
                color: "rgb(0,255,190)",
              },
            ],
            globalCoord: false,
          },
        },
        outline: {
          borderDistance: 0,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#00ffbe",
          },
        },
        color: "#00ffbe",
        label: {
          normal: {
            formatter: "",
          },
        },
      },
    ],
  };


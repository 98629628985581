/*
 * @Date 2022/03/17 17:28:30
 * 风向
 */
const windDirections = [
  { value: "北", range: [0.0, 11.25, 348.76, 360.0] },
  { value: "东北", range: [11.26, 78.75] },
  { value: "东", range: [78.76, 101.25] },
  { value: "东南", range: [123.76, 168.75] },
  { value: "南", range: [168.76, 191.25] },
  { value: "西南", range: [191.26, 258.75] },
  { value: "西", range: [258.76, 281.25] },
  { value: "西北", range: [281.26, 348.75] }
];

/*
 * @Date 2022/03/17 17:28:38
 * 风力等级
 */
const windLevels = [
  { value: "1", range: [0.0, 0.2] },
  { value: "2", range: [0.3, 1.5] },
  { value: "3", range: [1.6, 3.3] },
  { value: "4", range: [3.4, 5.4] },
  { value: "5", range: [5.5, 7.9] },
  { value: "6", range: [8.0, 10.7] },
  { value: "7", range: [10.8, 13.8] },
  { value: "8", range: [13.9, 17.1] },
  { value: "9", range: [17.2, 20.7] },
  { value: "10", range: [20.8, 24.4] },
  { value: "10", range: [24.5, 28.4] },
  { value: "11", range: [28.5, 32.6] },
  { value: "12", range: [32.7, 36.9] },
  { value: "13", range: [37.0, 41.4] },
  { value: "14", range: [37.0, 41.4] },
  { value: "15", range: [41.5, 46.1] },
  { value: "16", range: [46.2, 50.9] },
  { value: "17", range: [56.1, 1000.0] }
];

/*
 * @Date 2022/03/17 17:28:48
 * 降雨等级
 */
const rainLevel = [
  { value: "小", range: [0.01, 0.42] },
  { value: "中", range: [0.43, 1.34] },
  { value: "大", range: [1.35, 2.66] },
  { value: "暴", range: [2.67, 1000] }
]

/**
    * @Author KR0132
    * @Date 2022/03/01 14:48:01
    * @Description 解析风力方向数据
    */
export const parseWindDirection = (windDirection) => {
  for (let i = 0; i < windDirections.length; i++) {
    let direction = windDirections[i];
    let group = direction.range.length / 2;
    let begin;
    let end;
    for (let j = 0; j < group; j++) {
      begin = direction.range[j * 2];
      end = direction.range[j * 2 + 1];

      if (windDirection >= begin && windDirection <= end) {
        return direction.value;
      }
    }
  }
  return "北";
};

/**
 * @Author KR0132
 * @Date 2022/03/01 15:11:45
 * @Description 解析风力等级数据
 */
export const parseWindLevel = (windSpeed) => {
  for (let i = 0; i < windLevels.length; i++) {
    let level = windLevels[i];
    let group = level.range.length / 2;
    let begin;
    let end;
    for (let j = 0; j < group; j++) {
      begin = level.range[j * 2];
      end = level.range[j * 2 + 1];

      if (windSpeed >= begin && windSpeed <= end) {
        return level.value;
      }
    }
  }
  return "1";
}
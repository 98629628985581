<template>
  <div class="map-container" style="position:relative;" ref="olMap">
    <div id="olMap">
      <div id="popup" style="color:#000;" ref="popup"></div>
    </div>
    <el-radio-group v-model="mapTypeLabel" size="mini" style="position: absolute; z-index: 100; left: 2vw; top: 2vh">
      <el-radio-button label="道路"></el-radio-button>
      <el-radio-button label="卫星"></el-radio-button>
    </el-radio-group>
    <el-button ref="followBtn" class="followBtn" type="primary" @click="handleFollowRobot">追踪</el-button>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import "ol/ol.css";
import Map from "ol/Map";
import Feature from "ol/Feature";
import { LineString, Point } from "ol/geom";
import { fromLonLat, transform, toLonLat } from "ol/proj";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Fill, Text, Icon, Stroke, Style, Circle as CircleStyle } from "ol/style";
import XYZ from "ol/source/XYZ";
import OSM from "ol/source/OSM";
import Overlay from "ol/Overlay";
import { getVectorContext } from "ol/render";
import { composeCssTransform } from "ol/transform";
import Polyline from "ol/format/Polyline";
import Polygon from "ol/geom/Polygon";
import { mapState } from "vuex";
export default {
  name: "olMap",

  props: {
    lineArr: {
      type: Array,
      default: () => []
    }, //所有塔杆经纬度数组
    lineName: String, //线路名称
    towerNameArr: Array, //跟lineArr对应的杆塔名称
    robotCurrPos: Array, //机器人当前位置--经纬度数组
    isShowRobotPos: Boolean, //是否显示机器人位置
  },

  data() {
    return {
      mapTypeLabel: "道路",
      mapType: "pm", //地图类型
      olMap: null, //ol地图实例
      View: null, //地图视图
      olsource: null,
      raster: null,
      layerVectorSource: null, //图层源数据
      layerVectorLayer: null, //绘制图层
      robotIconFeature: null, //机器人Feature
      textColor: "#111",
      isShowPopup: false,
      towerName: "",
      // robotCurrPosExm: this.robotCurrPos
      robotCurrPosExm: [],
      isFollow: false,    // 是否开启跟踪模式

    };
  },

  mounted() {
    this.initMap();
  },

  watch: {
    /**
     * @Author KR0288
     * @Date 2022/06/24 09:36:30
     * @Description 监听地图类型
     */
    mapTypeLabel: function(newType, oldType) {
      if (newType == "道路") {
        this.textColor = "#111";
        this.mapType = "pm";
        this.raster.setSource(
          new XYZ({
            tileUrlFunction: function(tileCoord, pixelRatio, proj) {
              var z = tileCoord[0];
              var x = tileCoord[1];
              var y = tileCoord[2];
              // 百度瓦片服务url将负数使用M前缀来标识
              if (x < 0) {
                x = -x;
              }
              if (y < 0) {
                y = -y;
              }

              return window.IPConfig.mapURL + "/pm" + "/" + z + "/" + x + "/" + y + "/";
            },
            maxZoom: 18
          })
        );
        this.updateLineLayer();
      } else {
        this.textColor = "#ddd";
        this.mapType = "wx";
        this.raster.setSource(
          new XYZ({
            tileUrlFunction: function(tileCoord, pixelRatio, proj) {
              var z = tileCoord[0];
              var x = tileCoord[1];
              var y = tileCoord[2];
              // 百度瓦片服务url将负数使用M前缀来标识
              if (x < 0) {
                x = -x;
              }
              if (y < 0) {
                y = -y;
              }
              return window.IPConfig.mapURL + "/wx" + "/" + z + "/" + x + "/" + y + "/";
            },
            maxZoom: 18
          })
        );
        this.updateLineLayer();
      }
    },

    /*
     * @Date 2022/04/15 13:33:24
     * 监听机器人位置
     */
    robotCurrPosExm: function(newPos, oldPos) {
      // console.log('机器人位置发生变化',newPos,this.robotIconFeature,this.isShowRobotPos)
      if (newPos && this.robotIconFeature && this.isShowRobotPos) {
        this.layerVectorSource.removeFeature(this.robotIconFeature); //移除当前机器人Feature
        this.createRobotIcon(); //重新创建机器人Feature
        this.layerVectorSource.addFeature(this.robotIconFeature); //添加机器人Feature
        this.layerVectorLayer.changed();
        if(this.isFollow) {
          this.View.animate({
            center: fromLonLat(newPos),
            zoom: 16
          })          
        }
      }
    },

    /*
     * @Date 2022/06/24 09:36:22
     * 监听线路变化
     */
    lineArr: function(newLineArr, oldLineArr) {
      console.log(newLineArr,  oldLineArr);
      // if (this.layerVectorLayer) {
      //   this.$nextTick(() => {
      //     this.updateLineLayer();
      //     if(newLineArr) this.robotCurrPosExm = newLineArr[0]
      //   });
      // }
      this.$nextTick(() => {
        this.updateLineLayer();
        this.robotCurrPosExm = newLineArr[0] ? newLineArr[0] : [];
      }); 
    },

    /*
     * @Date 2022/04/15 13:37:08
     * 监听robotStatus的经纬变化
     */
    '$store.state.robotStatus.gpsPos': function(newVal, oldVal) {
      if(newVal && this.robotIconFeature && this.isShowRobotPos) {
        this.robotCurrPosExm = newVal.split(',').map(item => parseFloat(item));
      }
    },

    /*
     * @Date 2022/06/24 09:35:34
     * 是否正在追踪机器人位置，视图需要缩放
     */
    isFollow: function(newFlag, oldFlag) {
      this.View.cancelAnimations();
      if(!newFlag) {
        this.View.animate({
          center: fromLonLat(this.lineArr[parseInt(this.lineArr.length / 2) + 1]),
          zoom: 14
        })
      }else {
        this.View.animate({
          center: fromLonLat(this.robotCurrPosExm),
          zoom: 16
        })
      }
    },
  },

  computed: {
    /*
     * @Date 2022/06/24 09:36:51
     * 使用fromLonLat转换经纬度
     */
    towerLineArray() {
      var towerLineArray = [];
      this.lineArr.forEach((item, index, arr) => {
        towerLineArray.push([...fromLonLat([item[0], item[1]]), item[2], item[3], item[4]]);
      });
      console.log(towerLineArray);
      return towerLineArray;
    },

    /*
     * @Date 2022/06/24 09:36:56
     * 经纬度的最大最小值
     */
    fitBounds: function() {
      var lngArr = [],
        latArr = [];
      this.lineArr.forEach(item => {
        lngArr.push(item[0]);
      });
      this.lineArr.forEach(item => {
        latArr.push(item[1]);
      });
      lngArr.sort(function(a, b) {
        return a - b;
      });
      latArr.sort(function(a, b) {
        return a - b;
      });
      // lngArr.forEach((item, index, arr) => {
      //   lngArr[index] = fromLonLat(item)
      // });
      //   latArr.forEach((item, index, arr) => {
      //   latArr[index] = fromLonLat(item)
      // });
      return {
        lngArr,
        latArr
      };
    },
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/24 08:47:59
     * @Description 初始化地图
     */
    initMap() {
      let _this = this;

      // 1、获取离线瓦片
      this.olsource = new XYZ({
        tileUrlFunction: function(tileCoord, pixelRatio, proj) {
          var z = tileCoord[0];
          var x = tileCoord[1];
          var y = tileCoord[2];

          // 百度瓦片服务url将负数使用M前缀来标识
          if (x < 0) {
            x = -x;
          }
          if (y < 0) {
            y = -y;
          }
          return window.IPConfig.mapURL + "/" + _this.mapType + "/" + z + "/" + x + "/" + y + "/";
        },
        maxZoom: 18
      });
      // 在线地图
      // 高德地图层 http://webst0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}
      // this.olsource = new XYZ({
      //   url:'http://webst0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}'
      // });


      // 2、瓦片图层加入瓦片资源
      const raster = new TileLayer({
        source: this.olsource
      });
      this.raster = raster;


      // 3、获取视图
      this.View = new View({
        center: this.towerLineArray[0] ? this.towerLineArray[0] : [12946484.527499834, 5478572.266485001],
        // projection: "EPSG:4326",
        zoom: 14
      });


      // 4、创建地图
      this.olMap = new Map({
        layers: [raster], // 地图图层
        target: "olMap",  // 目标容器
        view: this.View   // 视图
        // controls: [],  // 地图控件
      });


      /* 5、创建地图完毕--以下是添加图层方法 */
      if(this.towerLineArray.length > 0) {
        // 5.1 创建要素
        var line = this.createLineFeature(this.towerLineArray);
        var icon = this.createIconFeature(this.towerLineArray);
        // var poly = this.createPolygonFeature();
        // var FeatureArray = [line, ...icon, poly];
        var FeatureArray = [line, ...icon];


        // 5.2 创建机器人要素
        if (this.isShowRobotPos) {
          var robot = this.createRobotIcon();
          FeatureArray.push(robot);  
        }

        // 5.3 创建矢量图层，并将要素添加进入
        this.createVectorLayer(FeatureArray);

        // 5.4 将矢量图层添加到地图容器中
        this.olMap.addLayer(this.layerVectorLayer);

        // 5.5 根据最大最小经纬度，自适应到矩形，让视图能展示所有的塔
        if(this.towerLineArray.length > 1) {
          var poly = this.createPolygonFeature();
          this.View.fit(poly.getGeometry(), { padding: [120, 120, 120, 120] });          
        }


        // this.olMap.on("click", function(evt) {
        //   var coordinate = evt.coordinate; //鼠标单击点的坐标
        //   _this.$emit("clickMap");
        // });
      }

      // 5.6 将具体信息的矩形框覆盖到地图上
      var element = document.getElementById("popup");
      var popup = new Overlay({
        element: element,
        positioning: "bottom-center",
        stopEvent: false,
        offset: [0, -50]
      });
      this.olMap.addOverlay(popup);


      // 5.7 鼠标移动事件
      this.olMap.on("pointermove", function(e) {
        var pixel = _this.olMap.getEventPixel(e.originalEvent);
        var feature = _this.olMap.forEachFeatureAtPixel(
          pixel,
          function(feature) {
            return feature;
          },
          { hitTolerance: 10 }
        );

        // 5.7.1 鼠标当前所在位置上没有要素就隐藏popup
        if (feature === undefined) {
          popup.element.style.display = "none";
          _this.olMap.getTargetElement().style.cursor = "auto";

        // 5.7.2 鼠标当前所在位置上有要素将popup设置在要素所在的坐标上
        } else {
          if (feature.values_.name && feature.values_.isWeather) {
            var coordinates = feature.getGeometry().getCoordinates();
            popup.setPosition(coordinates);
            console.log(feature);

            // 优化popup框底部信息不全(后面会完善popup不定宽高的情况下的情况)
            // 1、center和left部分的宽，center的高
            let offsetX = Number.parseInt(window.getComputedStyle(_this.$refs.olMap).width.replace('px', '')) + _this.$refs.olMap.offsetLeft;
            let offsetY = Number.parseInt(window.getComputedStyle(_this.$refs.olMap).height.replace('px', '')) + _this.$refs.olMap.offsetTop;

            // 2、获取map部分准备去容纳popup的距离
            let offsetLeft = offsetX - e.originalEvent.x;
            let offsetTop = offsetY - e.originalEvent.y;
            let offsetTopY = e.originalEvent.y - _this.$refs.olMap.offsetTop;

            // 3、判断距离是否能容纳
            if(offsetTop < 346 && offsetLeft < 455) {
              popup.setOffset([200 - (455 - offsetLeft), 200 - (346 - offsetTop)]);
            }else if(offsetTop < 346) {
              popup.setOffset([240, 200 - (346 - offsetTop)]);
            }else if(offsetLeft < 455) {
              if(offsetTopY < 175) {
                  popup.setOffset([200 - (455 - offsetLeft), 200 + 173 - offsetTopY]);
              }else {
                popup.setOffset([200 - (455 - offsetLeft), 200]);
              }
            }else {
              if(offsetTopY < 175) {
                popup.setOffset([200, 200 + 173 - offsetTopY]);
              }else {
                popup.setOffset([240, 200]);
              }
            }

            let weatherInfo = _this.$store.state.weatherStationDataList.find(item => {
              return item.deviceMac === feature.values_.deviceMac;
            })

            // 4、给popup填充内容
            var htmlNode = document.createElement("div");
            htmlNode.style.color = "#000";
            htmlNode.style.background = "rgba(222,222,222,.8)";
            htmlNode.style.padding = "10px";
            htmlNode.innerHTML = `
            <p >塔号: ${feature.values_.name}</p>
            <p >位置: [${toLonLat(coordinates)}]</p>
            <p >风速: ${weatherInfo?.windSpeed}</p>
            <p >风向: ${weatherInfo?.windDirection}</p>
            <p >光照: ${weatherInfo?.light}</p>
            <p >雨量: ${weatherInfo?.rainFall}</p>
            <p >湿度: ${weatherInfo?.humidity}</p>
            <p >温度: ${weatherInfo?.temperature}</p>
            <p >气压: ${weatherInfo?.pressure}</p>`;
            popup.setElement(htmlNode);
            popup.element.style.display = "block";
          }
          _this.olMap.getTargetElement().style.cursor = "pointer";
        }
      });

      console.log(this.olMap);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 08:51:57
     * @Description 创建矢量图层作为绘制层
     */
    createVectorLayer(FeatureArray) {
      var _this = this;
      // 实例化一个矢量图层Vector作为绘制层
      this.layerVectorSource = new VectorSource({
        features: [...FeatureArray]
      });
      this.layerVectorLayer = new VectorLayer({
        source: _this.layerVectorSource,
        style: function(feature) {
          if (feature.get("type") == "geom") {
            return new Style({
              // 矩形框的样式
              stroke: new Stroke({
                color: "rgba(255,255,255,0)",
                width: 2
              }),
              fill: new Fill({
                color: "rgba(255,255,255,0)"
              })
            });
          } else {
            return new Style({
              stroke: new Stroke({
                //线样式
                color: "#00caff",
                width: 5
              })
            });
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:26:07
     * @Description 创建矩形要素
     */
    createPolygonFeature() {
      var leftTop = [this.fitBounds.lngArr[0], this.fitBounds.latArr[this.fitBounds.latArr.length - 1]];
      var rightTop = [this.fitBounds.lngArr[this.fitBounds.lngArr.length - 1], this.fitBounds.latArr[this.fitBounds.latArr.length - 1]];
      var rightBottom = [this.fitBounds.lngArr[this.fitBounds.lngArr.length - 1], this.fitBounds.latArr[0]];
      var leftBottom = [this.fitBounds.lngArr[0], this.fitBounds.latArr[0]];
      var polygonArr = [[fromLonLat(leftTop), fromLonLat(rightTop), fromLonLat(rightBottom), fromLonLat(leftBottom)]];
      var polygonFeature = new Feature({
        type: "geom",
        geometry: new Polygon(polygonArr)
      });
      return polygonFeature;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:32:19
     * @Description 创建线要素
     */
    createLineFeature(lineArr) {
      var lineFeature = new Feature(new LineString(lineArr));
      return lineFeature;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:32:26
     * @Description 创建塔杆标注要素
     */
    createIconFeature(lineArr) {
      var _this = this;
      var IconFeatureArray = [];
      lineArr.forEach((item, index) => {
        console.log(item);
        var iconFeature = new Feature({
          geometry: new Point([item[0], item[1]]),
          name: _this.towerNameArr[index], //名称属性
          isStation: item[2],// 2022-04-13 自定义能判断传感器的属性
          isWeather: item[3],
          deviceMac: item[4]
        });
        // iconFeature.on('click',function(e){
        //   console.log('鼠标悬浮在塔上啦',e)
        // })
        // console.log('塔杆标注',iconFeature);
        iconFeature.setStyle(_this.createIconStyle(iconFeature));
        IconFeatureArray.push(iconFeature);
      });

      return IconFeatureArray;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:33:03
     * @Description 创建塔杆标注样式
     */
    createIconStyle(feature) {
      var _this = this;
      let styleArray = [];    // 2022-04-13
      this.IconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 60], //锚点
          anchorOrigin: "top-right", //锚点源
          anchorXUnits: "fraction", //锚点X值单位
          anchorYUnits: "pixels", //锚点Y值单位
          offsetOrigin: "top-right", //偏移原点
          opacity: 0.75,
          src: require("../assets/imgs/common/end.png") //图标的URL
        }),
        text: new Text({
          textAlign: "center", //位置
          textBaseline: "middle", //基准线
          font: "normal 16px 微软雅黑", //文字样式
          text: feature.get("name"), //文本内容
          fill: new Fill({
            //文本填充样式（即文字颜色)
            color: _this.textColor
          })
          // stroke: new Stroke({
          //   color: "#F00",
          //   width: 2,
          // }),
        })
      });

      // 2022-04-13 
      // 1、气象传感样式
      let WeatherStyle = (distance) => new Style({
        image: new Icon({
          anchor: [distance, 400], //锚点
          anchorOrigin: "top-right", //锚点源
          anchorXUnits: "fraction", //锚点X值单位
          anchorYUnits: "pixels", //锚点Y值单位
          offsetOrigin: "top-right", //偏移原点
          opacity: 0.75,
          src: require("../assets/imgs/common/sensor.webp"), //图标的URL
          scale: 0.1,
        })
      });

      // 2、充电样式 
      let chargeStyle = new Style({
        image: new Icon({
          anchor: [1.4, 400], //锚点
          anchorOrigin: "top-right", //锚点源
          anchorXUnits: "fraction", //锚点X值单位
          anchorYUnits: "pixels", //锚点Y值单位
          offsetOrigin: "top-right", //偏移原点
          opacity: 0.75,
          src: require("../assets/imgs/common/dian.webp"), //图标的URL
          scale: 0.1,
        })
      })

      // 3、判断自定义属性是否存在，如果有就将对应的样式添加到styleArray当中
      styleArray.push(this.IconStyle);
      if(feature.get('isWeather') && feature.get('isStation')) {
        styleArray.push(chargeStyle);
        styleArray.push(WeatherStyle(2.6));
      }else if(feature.get('isStation')) {
        styleArray.push(chargeStyle);
      }else if(feature.get('isWeather')) {
        styleArray.push(WeatherStyle(1.4));
      }
      return styleArray
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:33:11
     * @Description 创建机器人图标
     */
    createRobotIcon() {
      var _this = this;
      this.robotIconFeature = new Feature({
        type: "robotIcon",
        geometry: new Point(fromLonLat(_this.robotCurrPosExm))
      });

      var RobotIconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 60], //锚点
          anchorOrigin: "top-right", //锚点源
          anchorXUnits: "fraction", //锚点X值单位
          anchorYUnits: "pixels", //锚点Y值单位
          offsetOrigin: "top-right", //偏移原点
          // opacity: 0.75,
          src: require("../assets/imgs/common/mapMachine@2x.png") //图标的URL
        }),
        text: new Text({
          textAlign: "center", //位置
          textBaseline: "middle", //基准线
          font: "normal 14px 微软雅黑", //文字样式
          // text: "研发测试机", //文本内容
          fill: new Fill({
            //文本填充样式（即文字颜色)
            color: "#07325d"
          })
          // stroke: new Stroke({
          //   color: "#F00",
          //   width: 2,
          // }),
        })
      });

      this.robotIconFeature.setStyle(RobotIconStyle);

      return this.robotIconFeature;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 09:33:17
     * @Description 线路变化时重新渲染图层方法
     */
    updateLineLayer() {
      // 先移除原先图层
      this.olMap.removeLayer(this.layerVectorLayer);
      // console.log('this.towerLineArray',this.towerLineArray)
      // 创建新的线路 Feature
      if(this.lineArr.length > 0) {
        var line = this.createLineFeature(this.towerLineArray);
        var icon = this.createIconFeature(this.towerLineArray);

        var FeatureArray = [line, ...icon];

        // 创建机器人
        // if (this.isShowRobotPos && this.robotCurrPosExm.length === 2) {
        if (this.isShowRobotPos) {
          var robot = this.createRobotIcon();
          FeatureArray.push(robot);
        }

        //创建绘制层
        this.createVectorLayer(FeatureArray);

        if(this.towerLineArray.length > 1) {
          // 创建一个矩形
          var poly = this.createPolygonFeature();
          //自适应到矩形
          this.View.fit(poly.getGeometry(), { padding: [120, 120, 120, 120] });          
        } else if(this.towerLineArray.length === 1) {
          this.View.setCenter(this.towerLineArray[0]);
        }

        //将绘制层添加到地图容器中
        this.olMap.addLayer(this.layerVectorLayer);        
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/04/15 14:25:26
     * @Description 处理跟踪机器人gps位置
     */
    handleFollowRobot() {
      this.isFollow = !this.isFollow;
      document.getElementsByClassName('followBtn')[0].children[0].innerHTML = this. isFollow ?  '正在跟踪' : '跟踪'
    }
  }
};
</script>
<style scoped>
.map-container,
#olMap {
  position: relative;
  height: 100%;
  width: 100%;
}

.followBtn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 10%;
  height: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
  opacity: 0.7;
  
}
</style>

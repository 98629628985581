<template>
  <div style="width: 100%; height: 100%; background-color: #00412f" >
    <div :id="chartId" style="width: 100%; height: 100%; float: left" />
    <div style="position: relative">
      <el-select class="normal-select" style="position: absolute; top: 10px; right: 10px;  width: 6vw;" v-model="rangeTime" placeholder="请选择" @change="handleTimeChange">
        <el-option v-for="item in staticsTimes" :key="item.key" :label="item.value" :value="item.key"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import theme from "./chartTheme";
import {
  getPlanStatics,
  getStaticsTime
} from "@/http/homeApi.js";

export default {
  name: "planLineChart",
  data() {
    return {
      chartId: "plan-line-chart",
      staticsTimes: [], // 可选时间
      rangeTime: "",    // 时间段
      data: [],         // 巡检任务信息
      lineChart: "",    
    };
  },

  props: ["lineId"],

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/24 10:07:12
     * @Description 初始化图表
     */
    initChart() {
      // 1、初始化eChart
      this.lineChart = echarts.init(document.getElementById(this.chartId), "theme");

      // 2、配置eChart
      this.lineChart.setOption(this.lineOption);
      
      // 3、随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.lineChart.resize();
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 10:07:31
     * @Description 查询巡检任务
     */
    getData() {
      if(this.rangeTime && this.lineId) {
        let params = {lineId: this.lineId, staticsTime: this.rangeTime};
        getPlanStatics(params).then(res => {
          this.data = [];
          if(res.data && res.data.length>0) {
            for(let i=0; i<res.data.length; i++) {
              this.data.push(res.data[i])
            }
          }
          this.lineChart.clear();
          this.lineChart.setOption(this.lineOption);
        })
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 10:07:38
     * @Description 处理更改统计日期
     */
    handleTimeChange() {
      this.getData();
    }
  },

  computed: {

    /*
     * @Date 2022/03/28 10:41:32
     * eCharts 配置
     */
    lineOption: function() {
       return {
        title: {
          text: "  巡检统计",
          textStyle: {
            fontSize: 15,
            fontStyle: "normal",
            color: "#FFF"
          }
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          show: false
        },
        grid: {
          left: "8%",
          right: "10%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          feature: {
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: ["0%", "0%"],
          data: this.lineXAxisData,   
        },
        yAxis: {
          type: "value",
          min: 0,
          minInterval: 1,
          max: function(value){
            return Math.floor((value.max-value.min)*1.5);
          }
        },
        series: this.lineSerialData
      };
    },

     /*
    * @Date 2022/03/28 10:08:49
    * 柱状图X轴数据
    */
    lineXAxisData: function() {
      if(this.data && this.data.length>0) {
        console.log(this.data);
        return this.data.map(d => {
          return d.date;
        })
      }else {
        return ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
      }
    },

     /*
    * @Date 2022/03/28 10:09:05
    * tooltip数据
    */
    lineSerialData: function() {
      let readyData = [];
      let completeData = [];
      let failedData = [];
      let runningData = [];
      let discardData = [];
      let stopData = [];
      if(this.data && this.data.length>0) {
         for( let i=0; i<this.data.length; i++) {
          readyData.push(this.data[i].readyCount);
          completeData.push(this.data[i].completeCount);
          failedData.push(this.data[i].failedCount);
          runningData.push(this.data[i].runningCount);
          discardData.push(this.data[i].discardCount);
          stopData.push(this.data[i].stopCount);
        }
      }else {
        readyData = [1, 2, 3, 1, 4, 1, 3];
        completeData = [1, 3, 1, 4, 1, 3, 1];
        failedData = [3, 1, 3, 4, 1, 5, 1];
        runningData = [1, 4, 1, 3, 1, 2, 3];
        discardData = [1, 3, 4, 1, 3, 4, 5];
        stopData = [1, 4, 5, 1, 5, 2, 2];
      }
      return  [
          {
            name: "未开始",
            type: "line",
            stack: "Total",
            data: readyData
          },
          {
            name: "正在运行",
            type: "line",
            stack: "Total",
            data: runningData
          },
          {
            name: "已完成",
            type: "line",
            stack: "Total",
            data: completeData
          },
          {
            name: "失败",
            type: "line",
            stack: "Total",
            data: failedData
          },
          {
            name: "废弃",
            type: "line",
            stack: "Total",
            data: discardData
          },
          {
            name: "停止",
            type: "line",
            stack: "Total",
            data: stopData
          }
        ]
    }
  },

  watch: {
    lineId: function() {
      this.handleTimeChange();
    }
  },

  mounted() {
    echarts.registerTheme("theme", theme);
    getStaticsTime().then(res => {
      this.staticsTimes = res.data;
      this.rangeTime = this.staticsTimes[0].key;
      this.initChart();
    })
  }
};
</script>

<style lang="scss" scoped></style>

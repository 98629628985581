const theme = {
  color: ["#3fb1e3", "#6be6c1", "#bdc5e3", "#a0a7e6", "#c4ebad", "#96dee8"],
  backgroundColor: "rgba(0,54,43,0.7)",
  textStyle: {},
  title: {
    textStyle: {
      color: "#00ffbf"
    },
    subtextStyle: {
      color: "#00a880"
    }
  },
  line: {
    itemStyle: {
      borderWidth: "2"
    },
    lineStyle: {
      width: "3"
    },
    symbolSize: "8",
    symbol: "emptyDiamond",
    smooth: false
  },
  radar: {
    itemStyle: {
      borderWidth: "2"
    },
    lineStyle: {
      width: "3"
    },
    symbolSize: "8",
    symbol: "emptyDiamond",
    smooth: false
  },
  bar: {
    itemStyle: {
      barBorderWidth: "0",
      barBorderColor: "#bf4545"
    }
  },
  pie: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  scatter: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  boxplot: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  parallel: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  sankey: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  funnel: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  gauge: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    }
  },
  candlestick: {
    itemStyle: {
      color: "#e6a0d2",
      color0: "transparent",
      borderColor: "#e6a0d2",
      borderColor0: "#3fb1e3",
      borderWidth: "2"
    }
  },
  graph: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#bf4545"
    },
    lineStyle: {
      width: "1",
      color: "#ffffff"
    },
    symbolSize: "8",
    symbol: "emptyDiamond",
    smooth: false,
    color: ["#3fb1e3", "#6be6c1", "#bdc5e3", "#a0a7e6", "#c4ebad", "#96dee8"],
    label: {
      color: "#ffffff"
    }
  },
  map: {
    itemStyle: {
      areaColor: "#eeeeee",
      borderColor: "#aaaaaa",
      borderWidth: 0.5
    },
    label: {
      color: "#ffffff"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(63,177,227,0.25)",
        borderColor: "#3fb1e3",
        borderWidth: 1
      },
      label: {
        color: "#3fb1e3"
      }
    }
  },
  geo: {
    itemStyle: {
      areaColor: "#eeeeee",
      borderColor: "#aaaaaa",
      borderWidth: 0.5
    },
    label: {
      color: "#ffffff"
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(63,177,227,0.25)",
        borderColor: "#3fb1e3",
        borderWidth: 1
      },
      label: {
        color: "#3fb1e3"
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333"
      }
    },
    axisLabel: {
      show: true,
      color: "#ffffff"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333"
      }
    },
    axisLabel: {
      show: true,
      color: "#ffffff"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
      }
    }
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333"
      }
    },
    axisLabel: {
      show: true,
      color: "#ffffff"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff"
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333"
      }
    },
    axisLabel: {
      show: true,
      color: "#ffffff"
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
      }
    }
  },
  toolbox: {
    iconStyle: {
      borderColor: "#ffffff"
    },
    emphasis: {
      iconStyle: {
        borderColor: "#666666"
      }
    }
  },
  legend: {
    textStyle: {
      color: "#ffffff"
    }
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: "#ffffff",
        width: 1
      },
      crossStyle: {
        color: "#ffffff",
        width: 1
      }
    }
  },
  timeline: {
    lineStyle: {
      color: "#ffffff",
      width: 1
    },
    itemStyle: {
      color: "#626c91",
      borderWidth: 1
    },
    controlStyle: {
      color: "#626c91",
      borderColor: "#626c91",
      borderWidth: 0.5
    },
    checkpointStyle: {
      color: "#3fb1e3",
      borderColor: "#3fb1e3"
    },
    label: {
      color: "#626c91"
    },
    emphasis: {
      itemStyle: {
        color: "#626c91"
      },
      controlStyle: {
        color: "#626c91",
        borderColor: "#626c91",
        borderWidth: 0.5
      },
      label: {
        color: "#626c91"
      }
    }
  },
  visualMap: {
    color: ["#2a99c9", "#afe8ff"]
  },
  dataZoom: {
    backgroundColor: "rgba(255,255,255,0)",
    dataBackgroundColor: "rgba(222,222,222,1)",
    fillerColor: "rgba(114,230,212,0.25)",
    handleColor: "#cccccc",
    handleSize: "100%",
    textStyle: {
      color: "#999999"
    }
  },
  markPoint: {
    label: {
      color: "#ffffff"
    },
    emphasis: {
      label: {
        color: "#ffffff"
      }
    }
  }
};

export default theme;

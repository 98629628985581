<template>
  <div style="width: 100%; height: 100%;">
    <div :id="chartId" style="width: 100%; height: 100%; float: left" />
    <div style="position: relative">
      <el-select class="normal-select" style="position: absolute; top: 10px; right: 10px; width: 6vw;" v-model="rangeTime" placeholder="请选择" @change="handleTimeChange">
        <el-option v-for="item in staticsTimes" :key="item.key" :label="item.value" :value="item.key"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import theme from "./chartTheme";
import {
  getErrandStatics,
  getStaticsTime
} from "@/http/homeApi.js";
export default {
  name: "analysisBarChart",
  data() {
    return {
      chartId: "analysis-bar-chart",
      staticsTimes: [],
      rangeTime: "",
      data: [],
      barChart: "",
    }
  },
  props: ["lineId"],
  methods: {
    initChart() {
      // 初始化echart
      this.barChart = echarts.init(document.getElementById(this.chartId), "theme");
      // 配置echart参数
      this.barChart.setOption(this.barOption);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.barChart.resize();
      });
    },

    /*
     * @Date 2022/03/28 09:52:25
     * 查询统计数据
     */
    getData() {
      if(this.rangeTime && this.lineId) {
        let params = {
          lineId: this.lineId, 
          staticsTime: this.rangeTime
        };
        getErrandStatics(params).then(res => {
          this.data = [];
          if(res.data && res.data.length>0) {
            for(let i=0; i<res.data.length; i++) {
              this.data.push(res.data[i])
            }
          }
          this.barChart.setOption(this.barOption);
        })
      }
    },

    /*
     * @Date 2022/03/28 11:26:56
     * 处理更改统计日期
     */
    handleTimeChange() {
      this.getData();
      
    }
  },
  mounted() {
    echarts.registerTheme("theme", theme);
    this.initChart();

    getStaticsTime().then(res => {
      this.staticsTimes = res.data;
      this.rangeTime = this.staticsTimes[0].key;
    })
  },
  computed: {
    
    /*
     * @Date 2022/03/28 10:08:37
     * 柱状图配置
     */
    barOption: function() {
      let that = this;
      return {
        title: {
          text: "智能分析",
          textStyle: {
            fontSize: 15,
            fontStyle: "normal",
            color: "#FFF"
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: that.barXAxisData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis:{},
        series: that.barSerialData
      }; 
    },
    /*
    * @Date 2022/03/28 10:08:49
    * 柱状图X轴数据
    */
    barXAxisData: function() {
      if(this.data && this.data.length>0) {
        return this.data.map(d => {
          return d.date;
        })
      }else {
        return ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
      }
    },

    /*
    * @Date 2022/03/28 10:09:05
    * 柱状图数据
    */
    barSerialData: function() {
      let totalData = [];
      let normalData = [];
      let defectData = [];
      if(this.data && this.data.length>0) {
        for( let i=0; i<this.data.length; i++) {
            totalData.push(this.data[i].total);
            normalData.push(this.data[i].normalCount);
            defectData.push(this.data[i].defectCount);
        }
       
      }else {
        totalData = [10, 52, 200, 334, 390, 330, 220];
        normalData = [10, 52, 22, 33, 21, 33, 220];
        defectData = [6, 75, 34, 55, 343, 12, 3];
      }
      return  [{
              name: "全部",
              type: "bar",
              data: totalData
            },
            {
              name: "正常",
              type: "line",
              data: normalData
            },
            {
              name: "缺陷",
              type: "line",
              data: defectData
            }];
    }
  },
  watch: {
     lineId: function() {
      this.handleTimeChange();
    }
  }

  

};
</script>

<style lang="scss" scoped></style>

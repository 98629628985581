<template>
  <div class="echart-liquid">
    <div class="liquid" :id="id"></div>
  </div>
</template>
<script>
import echarts from "echarts";
import "echarts-liquidfill";
import { LiquidOption } from "@/utils/js/echartsOption.js";
export default {
  name: "echartLiquid",
  props: ["id", "optionValue"],
  data() {
    return {
      myEchart: null
    };
  },
  methods: {
    // 渲染echarts
    createEcharts(id, option) {
      var myChart = echarts.init(document.getElementById(id));
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

      myChart.on("click", function(params) {
        console.log(params);
      });

      // 原生JS写法  让echarts随窗口大小变化
      window.onresize = function() {
        myChart.resize();
      };

      return myChart;
    }
  },
  watch: {
    optionValue: function(newValue) {
      var newOption = JSON.parse(JSON.stringify(LiquidOption));
      newOption.title.text = newValue;
      this.myEchart.setOption(newOption);
    }
  },
  mounted() {
    this.myEchart = this.createEcharts(this.id, LiquidOption);
  }
};
</script>
<style lang="scss" scoped>
.echart-liquid {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .liquid {
    margin-top: 2vh;
    height: 60%;
    width: 60%;
  }
}
</style>

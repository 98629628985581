<template>
  <div class="home-page">
    <div class="top-router">
      <RouterMenu />
    </div>
    <div class="main">
      <div class="left">
        <!-- 线路管理 -->
        <div class="son-header son-header-button">
          <div style="display: flex; flex-grow: 1">
            <Icon name="lineInfo" class="icon-class" />
            线路管理
          </div>
          <div v-if="$hasElement('line-btn-get')" class="filterline-button top-button" @click="filterLineDialogVisible = true" title="过滤线路"></div>
          <div v-if="$hasElement('line-btn-add')" class="addline-button top-button" @click="addLineBtn" title="添加线路"></div>
          <div v-if="$hasElement('line-btn-synchronize')" class="syncronize-button top-button" @click="synchronizeLine" title="同步线路"></div>
        </div>
        <div class="line-list">
          <div class="sub-title">
            <span>线路名</span>
            <span>塔杆数</span>
            <span>操作</span>
          </div>
          <ul>
            <li v-for="(line, index) in filterLineList" :key="index" :class="index == lineIndex ? 'li-actived' : ''" @click="getLineDetailsByLineId(line, index)">
              <span>
                <Icon name="greenLine" />
                {{ line.lineName }}
              </span>
              <span @click.stop="goToTowerList(line)" title="点击查看塔杆详细信息" class="tower-count" v-if="$hasElement('tower-tab-get')">{{ line.towerTotal }}</span>
              <span @click.stop="handleEdit(line)" title="编辑线路信息"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;" v-if="$hasElement('line-btn-update')"></i></span>
            </li>
          </ul>
        </div>

        <!-- 智能巡检 / 分析 -->
        <div class="son-header img-warn">
          <div style="display: flex; flex-grow: 1">
            <Icon name="defect" class="icon-class" />
            智能巡检
          </div>
          <div class="weather-station-button top-button" @click="handleCheckWeatherStation" title="查看气象基站"></div>
        </div>
        <div class="img-warn-list">
          <div class="weather-class">
            <weather-radar-chart></weather-radar-chart>
          </div>
          <div class="warn-class">
            <analysis-bar-chart :lineId="lineId"></analysis-bar-chart>
          </div>
        </div>
      </div>

      <!-- 地图或视频 -->
      <div class="center">
        <LiverPlayer v-if="isShowMiniMap" class="bottom-video" h5id="homebigId" :token="centerVideoToken" :isShowMiddleBtn="true" @h5sHandle="showBigVideo" />
        <OlMap :lineArr="lineArray" :towerNameArr="towerName" :robotCurrPos="robotPos" :isShowRobotPos="true" :lineName="lineName" :class="isShowMiniMap ? 'mini-map' : ''" @clickMap="showBigMap" />
        <div class="bottom-liveplayer">
          <LiverPlayer
            v-for="(camera, index) in lineCamera"
            :key="index"
            class="bottom-video"
            :h5id="camera.h5sToken + 'homemini'"
            :token="camera.h5sToken"
            :isShowMiddleBtn="true"
            :isDisable="false"
            @h5sHandle="showBigVideo"
          />
        </div>
      </div>


      <div class="right">
        <!-- 今日巡检 -->
        <div class="son-header baseInfo-ctrl">
          <div style="display: flex; flex-grow: 1">
            <Icon name="greenMachine" class="icon-class" />
            今日巡检
          </div>
        </div>
        <div class="baseInfo">
          <div class="task-list">
            <div class="title">
              <span>任务名</span>
              <span>任务类型</span>
              <span>执行状态</span>
            </div>
            <ul>
              <li v-for="(plan, index) in planList" :key="index">
                <span :title="plan.planName" class="plan-name">{{ plan.planName }}</span>
                <span>{{ plan.planType }}</span>
                <span>{{ plan.planStatus }}</span>
              </li>
            </ul>
          </div>

          <!-- 巡检统计 -->
          <div class="home-log">
            <plan-line-chart :lineId="lineId"></plan-line-chart>
          </div>
        </div>

        <!-- 巡检照片 -->
        <div class="son-header">
          <div style="display: flex; flex-grow: 1;">
            <Icon name="pictureInfo" class="icon-class" />
            巡检图片
          </div>
        </div>     
        <div class="img-class">
          <vue-seamless-scroll :data="imgList" class="seamless-warp" :class-option="vueSeamlessScrollOptions2">
            <ul>
              <li class="DataList_top" v-for="(img, index) in imgList" :key="index">
                <img :src="img.thumbImg" :alt="img.imgName" :title="img.imgName" width="100%" @click="(dialogImgObj = img),(dialogImgVisible = true)" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>

    <div>
      <!-- 添加线路对话框 -->
      <el-dialog class="my-dialog" title="线路信息" :close-on-click-modal="false" :visible.sync="lineDialogVisible" @closed="closeAdd" :popper-append-to-body="false">
        <el-form :model="lineForm" :rules="lineFormRules" ref="lineForm" class="line-form-class" prop="type">
          <el-form-item label="供电局" :label-width="formLabelWidth" clearable filterable prop="bureauId">
            <el-select v-model="lineForm.bureauId" clearable filterable placeholder="请选择所属公司">
              <el-option v-for="(item, index) in bureauList" :key="index" :label="item.bureauName" :value="item.bureauId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="定位城市" :label-width="formLabelWidth" prop="provinceId" >
            <div class="position">
              <el-select v-model="lineForm.provinceId" placeholder="请选择省" clearable filterable @change="handleChangeProvince" class="province-class">
                <el-option v-for="(item, index) in provinceList" :key="index" :label="item.provinceName" :value="item.provinceId"></el-option>
              </el-select>
              <el-select v-model="lineForm.cityId" placeholder="请选择市" clearable filterable @change="handleChangeCity" class="city-class">
                <el-option v-for="(item, index) in cityList" :key="index" :label="item.cityName" :value="item.cityId"></el-option>
              </el-select>
              <el-select v-model="lineForm.countyId" placeholder="请选择区 / 县" clearable filterable class="county-class">
                <el-option v-for="(item, index) in countyList" :key="index" :label="item.countyName" :value="item.countyId"></el-option>
              </el-select>
            </div>
          </el-form-item>   

          <el-form-item label="线路名称" :label-width="formLabelWidth" prop="lineName">
            <el-input v-model.trim="lineForm.lineName" auto-complete="off" placeholder="请输入线路名称1-50字符"></el-input>
          </el-form-item>
          <el-form-item label="电压等级" :label-width="formLabelWidth" prop="voltage">
            <el-input v-model.trim="lineForm.voltage" auto-complete="off" placeholder="请输入电压等级" class="voltage-level" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="线路长度" :label-width="formLabelWidth" prop="lineLength">
            <el-input v-model.trim="lineForm.lineLength" auto-complete="off" placeholder="请输入线路长度" class="line-length" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="投运时间" :label-width="formLabelWidth" prop="serviceBeginTime">
            <el-date-picker v-model="lineForm.serviceBeginTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择投运时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="起始塔杆" :label-width="formLabelWidth" prop="beginTower">
            <el-input v-model.trim="lineForm.beginTower" auto-complete="off" placeholder="请输入起始塔杆" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="终止塔杆" :label-width="formLabelWidth" prop="endTower">
            <el-input v-model.trim="lineForm.endTower" auto-complete="off" placeholder="请输入终止塔杆" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="线路状态" :label-width="formLabelWidth" prop="lineStatus">
            <el-radio-group class="line-status" v-model="selectStatus" @change="groupChange">
              <el-radio v-for="status in activeStatus" :label="status.value" :key="status.key">{{ status.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注内容" :label-width="formLabelWidth" prop="remark">
            <el-input v-model.trim="lineForm.remark" auto-complete="off" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注内容"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" v-if="isAddLine">
          <el-button class="cancel-button" @click="closeAdd">取 消 新 增</el-button>
          <el-button class="save-button" @click="confirmAdd">提 交 新 增</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-else>
          <el-button class="cancel-button" @click="deleteLineBtn" v-if="$hasElement('line-btn-delete')">删 除 线 路</el-button>
          <el-button class="save-button" @click="updateLineBtn">提 交 修 改</el-button>
        </span>
      </el-dialog>

      <!-- 图片查看对话框 -->
      <el-dialog :title="dialogImgObj.imgName" :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="80%" top="1vh" class="home-img-dialog" @close="dialogImgObj = {}">
        <img :src="dialogImgObj.originImg" alt="" width="100%" />
      </el-dialog>

      <!-- 过滤线路对话窗口 -->
      <el-dialog class="my-dialog home-filter-dialog" title="筛选线路" :close-on-click-modal="false" :visible.sync="filterLineDialogVisible">
        <el-form :model="filterForm" ref="filterLineForm">
          <el-form-item label="供电局" :label-width="formLabelWidth" prop="bureauId">
            <el-select v-model="filterForm.bureauId" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in bureauList" :key="index" :label="item.bureauName" :value="item.bureauId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线路名" :label-width="formLabelWidth" prop="lineName">
            <el-input v-model.trim="filterForm.lineName" auto-complete="off" placeholder="请输入线路名称关键字"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancel-button" @click="filterLine(false)">取 消 筛 选</el-button>
          <el-button class="save-button" @click="filterLine(true)">应 用 筛 选</el-button>
        </span>
      </el-dialog>

      <!-- 同步线路对话窗口 -->
      <el-dialog
        class="my-dialog2 sync-line-dialog"
        title="同步线路"
        :close-on-click-modal="false"
        :visible.sync="synchronizeLineDialogVisible"
        width="70vw"
        top="10vh"
        max-height="500"
        @close="handleClose('remoteLineFormRef')"
      >
        <el-form :inline="true" :model="remoteLineForm" :rules="remoteLineFormRules" ref="remoteLineFormRef"  label-position="right">
          <el-form-item label="省" prop="provinceId">
            <el-select v-model="remoteLineForm.provinceId" placeholder="请选择省" clearable filterable @change="handleChangeProvince">
              <el-option v-for="(item, index) in provinceList" :key="index" :label="item.provinceName" :value="item.provinceId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市" prop="cityId">
            <el-select v-model="remoteLineForm.cityId" placeholder="请选择市" clearable filterable @change="handleChangeCity">
              <el-option v-for="(item, index) in cityList" :key="index" :label="item.cityName" :value="item.cityId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区/县" prop="countyId">
            <el-select v-model="remoteLineForm.countyId" placeholder="请选择区/县" clearable filterable>
              <el-option v-for="(item, index) in countyList" :key="index" :label="item.countyName" :value="item.countyId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线路名称" prop="key">
            <el-input v-model="remoteLineForm.key" placeholder="请输入线路名称关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="kr-normal-button el-icon-search" @click="handleGetLineFromRemote">查 询</el-button>
          </el-form-item>
        </el-form>
        <!-- 查询到的线路 -->
        <el-table
          v-loading="isLoadingLine"
          element-loading-text="查询线路中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="syncLineList"
          ref="syncLineTableRef"
          empty-text="没有查询到匹配的线路"
          height="800"
          style="max-height: 70vh"
          size="medium"
          @expand-change="handleLineRowExpand"
        >
          <el-table-column type="expand" align="center" width="40">
            <template slot-scope="props">
              <el-table :data="props.row.towerList" style="margin: auto; width: 90%" max-height="300">
                <el-table-column label="序号" align="center" type="index" width="100"></el-table-column>
                <el-table-column prop="towerName" label="杆塔名称" align="center"></el-table-column>
                <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
                <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>
                <el-table-column prop="type" label="类型" align="center"></el-table-column>
                <el-table-column prop="height" label="高度" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.height }}m</span>
                  </template>
                </el-table-column>
                <el-table-column prop="practicalHeight" label="呼高" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.practicalHeight }}m</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
          <el-table-column prop="lineName" label="线路名" align="center"></el-table-column>
          <el-table-column prop="location" label="地区" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.province + scope.row.city + scope.row.county }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="length" label="线路长度" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.length }}km</span>
            </template>
          </el-table-column>
          <el-table-column prop="location" label="电压等级" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.voltage }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="serviceBeginTime" label="投运时间" align="center" width="180"></el-table-column>
          <el-table-column prop="beginTower" label="起始杆塔" align="center"></el-table-column>
          <el-table-column prop="endTower" label="结束杆塔" align="center"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleSyncLine(scope.row)" type="text" class="table-btn el-icon-refresh" title="同步线路">
                同 步
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!-- 开始同步线路表单 -->
      <el-dialog class="my-dialog" title="同步线路" :close-on-click-modal="false" :visible.sync="beginSyncLineDialogVisible" top="20vh">
        <el-form :model="syncLineForm" :rules="syncLineFormRules" ref="syncLineFormRef" label-width="6em" label-position="right" style="margin: auto">
          <el-form-item label="所属公司" prop="bureauId">
            <el-select v-model="syncLineForm.bureauId" placeholder="请选择所属公司" clearable filterable>
              <el-option v-for="(item, index) in syncBureauList" :key="index" :label="item.bureauName" :value="item.bureauId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="同步线路" prop="lineId">
            <el-select v-model="syncLineForm.lineId" placeholder="请选择巡检线路" disabled>
              <el-option v-for="(item, index) in syncLineList" :key="index" :label="item.lineName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancel-button" @click="beginSyncLineDialogVisible = false">取 消</el-button>
          <el-button class="save-button" @click="beginSyncLine">立即同步</el-button>
        </span>
      </el-dialog>

      <!-- 查看气象基站对话框 -->
      <el-dialog class="my-dialog2" title="动态气象监测站" :close-on-click-modal="false" :visible.sync="weatherDialogVisible" width="50vw" >
        <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 2vh">
          <el-button class="kr-normal-button" size="small" @click="updateOpenWeatherStationDialog">监测配置</el-button>
        </div>
        <weather-full-view></weather-full-view>
      </el-dialog>

      <!-- 修改气象基站对话框 -->
      <el-dialog class="my-dialog2" title="配置气象监测站" :close-on-click-modal="false" :visible.sync="weatherConfigDialogVisible" width="30vw" top="20vh" @closed=closeWeatherConfigDialog>
          <el-form  :model="listenLineForm"  ref="listenLineFormRef" class="demo-form-inline" label-width="80px" prop="type">
            <el-form-item label="当前线路" prop="lineId">
                <el-select class="normal-select" v-model="listenLineForm.lineId" disabled placeholder="请选择巡检线路" @change="handleChangeLine">
                  <el-option v-for="(line, index) in lineList" :key="index" :label="line.lineName" :value="line.lineId"/>
                </el-select>
              </el-form-item>
              <el-form-item label="气象监测站">
                <el-checkbox-group v-model="listenLineForm.weatherStations">
                <el-checkbox v-for="(station, index) in selectWeatherStation" :key="index" :label="station.weatherStationName"></el-checkbox>
              </el-checkbox-group>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="cancel-button" @click="closeWeatherConfigDialog">取 消</el-button>
            <el-button class="save-button" @click="updateConfigWeatherMessage">确 认</el-button>
          </span>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import OlMap from "@/components/OlMap.vue";
import Icon from "@/components/Icon.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import echartLiquid from "@/components/echartLiquid.vue";
import moment from "moment";
import LiverPlayer from "@/components/LivePlayer.vue";
import { mapMutations, mapState } from "vuex";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import planLineChart from "@/components/charts/planLineChart.vue";
import weatherRadarChart from "@/components/charts/weatherRadarChart.vue";
import analysisBarChart from "@/components/charts/analysisBarChart.vue";
import {
  changeLine,
  addLine,
  updateLine,
  deleteLine,
  getLineNames,
  getLineById,
  getselectTowers,
  getinspectImages,
  getqueryPhotoByDef,
  getPlansByLineId,
  getLineGps,
  getTowersGps,
  getCircuitTypeList,
  getWireTypeList,
  getBureauList,
  getBureauRegion,
  getProvinces,
  getCounties,
  getCities,
  getLineFromDataSystem,
  SyncLineFromDataSystem,
  getBureasByRegion,
  getErrandStatics,
  getPlanStatics,
  getStaticsTime,

} from "@/http/homeApi.js";

import {
  beginPushWeatherData, 
  getLineWeatherPushConfig, 
  getSubscribingWeathers, 
  updateSubscribeWeatherStations,
  updateSubscribeCity
} from "@/http/equipmentApi.js"

import weatherFullView from "@/components/common/weatherFullView.vue"; 
import { checkLogin } from "@/http/api.js";
import { 
  handleChangeCity as handleChangeWeather 
} from "@/utils/websocket/appWebsocket.js"
import { md5Encryption2 } from "@/utils/js/md5.js"

export default {
  name: "home",

  components: {
    RouterMenu,
    OlMap,
    Icon,
    vueSeamlessScroll,
    echartLiquid,
    LiverPlayer,
    planLineChart,
    weatherRadarChart,
    analysisBarChart,
    weatherFullView,
  },

  data() {
    return {
      /*
       * @Date 2022/06/10 11:16:07
       * 过滤线路信息
       */
      filterLineDialogVisible: false,     // 筛选线路对话框
      filterForm: {                       // 筛选线路表单
        bureauId: "",
        lineName: ""
      },
      filterLineList: [],                 // 筛选过滤后的线路


      /*
       * @Date 2022/06/10 11:14:16
       * 新增 / 修改线路信息
       */
      lineDialogVisible: false,   // 线路对话框
      isAddLine: true,            // 是否添加线路
      formLabelWidth: "4.5vw",    // 表单label宽度
      lineForm: {                 // 提交表单参数
        id: "",
        bureauId: "",             // 供电局ID
        provinceId: "",
        cityId: "",
        countyId: "",
        lineName: "",
        voltage: "",
        lineLength: "",
        serviceBeginTime: "",
        beginTower: "",
        endTower: "",
        lineStatus: true,
        remark: ""
      },
      lineFormRules: {            // 表单校验规则
        bureauId: [{ required: true, message: "请选择供电局", trigger: "blur" }],
        lineName: [{ required: true, message: "请输入线路名称", trigger: "blur" }],
        voltage: [{ required: true, message: "请输入电压等级", trigger: "blur" }],
        lineLength: [{ required: true, message: "请输入线路长度", trigger: "blur" }],
        serviceBeginTime: [{ required: true, message: "请选择投运时间", trigger: "blur" }],
        provinceId: [{ required: true, message: "请选择城市", trigger: "blur" }],
        lineStatus: [{ required: true, message: "请选择线路状态", trigger: "change" }]
      },
      bureauList: [],            // 下拉选框供电局列表
      selectStatus: '正常运行',   // 当前线路状态中文文本
      activeStatus: [            // 可选择的线路状态
        { key: 1, value: "正常运行" },
        { key: 2, value: "禁用" }
      ],
      provinceList: [],          // 可选择的省市区
      cityList: [],
      countyList: [],


      /*
       * @Date 2022/06/10 11:23:14
       * 查询同步线路信息
       */
      synchronizeLineDialogVisible: false,      // 同步线路对话窗口显示状态
      remoteLineForm: {                         // 和汇编系统同步线路表单
        key: "",
        provinceId: "",
        cityId: "",
        countyId: ""
      },
      remoteLineFormRules: {                    // 同步线路表单
        provinceId: [{ required: true, message: "请选择省份", trigger: "blur" }]
      },
      syncLineList: [],                         // 向汇编系统查询返回的线路列表


      /*
       * @Date 2022/06/10 11:37:10
       * 同步线路
       */
      beginSyncLineDialogVisible: false,        // 立即同步对话框显示状态
      isLoadingLine: false,                     // 从汇编系统加载线路loading
      syncLineForm: {                           // 同步线路表单
        lineId: "",
        bureauId: ""
      },
      syncBureauList: "",                       // 可选的供电局
      syncLineFormRules: {                      // 校验规则
        lineId: [{ required: true, message: "请选择线路", trigger: "blur" }],
        bureauId: [{ required: true, message: "请选择公司", trigger: "blur" }]
      },
      lastRow: "",                              // 记录上一次展开的表格行


      /*
       * @Date 2022/06/10 13:26:51
       * 线路列表信息
       */
      lineIndex: 0,            // 当前点击的线路索引
      dialogImgObj: {},        // 图片数据


      /*
       * @Date 2022/06/10 11:45:15
       * 气象基站信息
       */
      weatherDialogVisible: false,        // 查看气象基站信息
      weatherStationDataList: [],         // 查询到的气象基站信息
      weatherConfigDialogVisible: false,  // 修改该线路的气象基站配置
      listenLineForm: {                   // 气象基站配置
        lineId: "",
        weatherStations: [],
      },
      selectWeatherStation: [],           // 可选择的气象基站
      lineList: [],                       // 线路列表


      /*
       * @Date 2022/06/10 13:23:26
       * 地图信息
       */
      isShowMiniMap: false,               // 是否展示小地图
      mapType: "pm",                      // 地图类型
      robotPos: [],                       // 机器人GPS位置
      lineName: "",                       // 线路名称
      // lineDetails: {},                   //线路详细信息
      lineArray: [                        // 塔杆经纬度数组-二维数组
        // [116.45868019, 39.67498893],
        // [116.4994097, 39.69882042],
        // [116.5994123, 39.67982123]
      ],
      lineCamera: [],                     // 显示的摄像头
      centerVideoToken: "",               // 获取视频流所需token
      towerName: [],                      // 对应的塔杆名称


      /*
       * @Date 2022/06/10 13:40:53
       * 今日巡检任务
       */
      planList: [],       // 巡检计划列表



      /*
       * @Date 2022/06/10 13:20:56
       * 巡检图片
       */
      dialogImgVisible: false,            // 图片查看对话框
      defectList: [],                     // 缺陷列表
      imgList: [],                        // 图片列表

      
      
      circuitList: [], //下拉选回路数列表
      wireTypeList: [], //下拉选线路类型
      leftWireTypeList: [], //下拉选左地线类型
      rightWireTypeList: [], //下拉选右地线类型
      logList: [],
      
    };
  },

  computed: {
    ...mapState(["socketContent", "lineId", "visitorId"]),
    // 滚动列表配置
    vueSeamlessScrollOptions1() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.defectList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    },
    vueSeamlessScrollOptions2() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.imgList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },

  watch: {
    socketContent: {
      handler(newValue) {
        console.log("home传感器数据变化", newValue);
        if (newValue.motorstate != "") {
          let logTime = moment().format("HH:mm:ss");
          if (newValue.motorstate == -1) {
            this.logList.push({
              logTime: logTime,
              log: "刷新成功"
            });
          } else {
            var log = "";
            if (newValue.data.count != null && newValue.data.count != "") {
              log = newValue.motorstate + " " + newValue.data.count / 1000 + "m";
            } else {
              log = newValue.motorstate;
            }
            this.logList.push({
              logTime: logTime,
              log: log
            });
          }
        }
      },
      deep: true
    },
  },

  mounted() {
    this.getProvinceData();
  },

  created() {
    const query = this.$route.query || {};
    console.log(query);
    if (query.authorization && query.userName) {
      showLoading();
      checkLogin({ userName: query.userName, authorization: decodeURIComponent(query.authorization) })
        .then(res => {
          closeLoading();
          if (res.meta.code == 200) {
            localStorage.setItem("userInfo", JSON.stringify({ userName: query.userName }));
            sessionStorage.setItem("Authorization", res.data);
            this.$store.commit("saveUserMessage", query.userName);
            // 登录成功调用App.vue中的websocket方法
            this.$parent.createWs();
            this.init();
          } else {
            this.$message.error(res.data);
          }
        })
        .catch(err => {
          closeLoading();
          console.log(err);
          this.$message.error(err.data.data);
        });
    } else {
      this.init();
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/10 14:32:30
     * @Description 初始化
     */
    init() {
      this.getAllLineList();
      this.getAllNextBureausList();
      this.getLineCircuitTypeList();
      this.getLineWireTypeList();
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:31:04
     * @Description 获取供电局下所有的线路
     */
    getAllLineList() {
      getLineNames().then(res => {
        this.lineList = res.data;
        this.filterLineList = this.lineList;
        this.getLineDetailsByLineId(res.data[this.lineIndex], this.lineIndex);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:25:46
     * @Description 根据lineId查询线路信息
     */
    async getLineDetailsByLineId(line, index) {
      
      // 1、请求开启推送气象数据
      let params = {
        preLineId: this.lineId, 
        curLineId: line.lineId, 
        websocketUser: this.visitorId
      };
      beginPushWeatherData(params).then(res => {
        if(res.code==="000000") {
           let city = res.data.city;
           if(city) {
             this.$store.commit("updateCity", city);
           }
           let stationList = res.data.stationList ? res.data.stationList : [];
           this.$store.commit("updateWeatherStationList", stationList);
        }else {
          this.$message.warning(res.data ? res.data : res.mesg);
        }
      })

      // 2、将选中的线路保存到vuex
      this.$store.commit("saveLineId", line.lineId);
      this.lineIndex = index || 0;                  // 保存刚刚点击的线路索引


      // let lineDetails = await getqueryLineInfo({ lineName: line.lineName });
      // this.lineDetails = lineDetails;
      // this.lineName = line.lineName;
      // this.lineCamera = [];
      // if (lineDetails.cameraList != null && lineDetails.cameraList.length > 0) {
      //   lineDetails.cameraList.forEach(camera => {
      //     if (camera.rtmp.match("_live2") || camera.rtmp.match("_live3")) {
      //       let h5stoken = camera.rtmp.split("/").slice(-1)[0];
      //       camera.h5sToken = h5stoken;
      //       this.lineCamera.push(camera);
      //     }
      //   });
      // }


      // TODO 3、需要查询出杆塔的GPS坐标
      let towerMessage = await getLineGps({ lineId: line.lineId });
      if (towerMessage.total != 0) {
        var towerName = towerMessage.rows.map(tower => tower.towerName);
        // var lineArray = towerMessage.rows.map(tower => [tower.longitude, tower.latitude]);
        var lineArray = towerMessage.rows.map(tower => [tower.longitude, tower.latitude, tower.isStation, tower.isWeather, tower.deviceMac]);    
        this.lineArray = lineArray;
        this.towerName = towerName;
      } else {
        this.lineArray = [];
      }

      // 4、获取该线路机器对应的巡检图片
      let imgMessage = await getinspectImages({ lineId: line.lineId });
      if (imgMessage.rows) {
        this.imgList = await Promise.all(imgMessage.rows.map(async item => {
          item.thumbImg = await md5Encryption2(item.thumbImg);
          item.originImg = await md5Encryption2(item.originImg);
          return item;
        }));
        console.log(this.imgList);
      } else {
        this.imgList = [];
      }
      

      // TODO 5、获取巡检缺陷图片统计
      // let warnMessage = await getqueryPhotoByDef({lineId: line.lineId});
      // // console.log("warnMessage", warnMessage);
      // if (warnMessage.rows) {
      //   this.defectList = warnMessage.rows;
      // } else {
      //   this.defectList = [];
      // }


      // 6、获取该线路今天的巡检任务
      let planMessage = await getPlansByLineId({ lineId: line.lineId });
      if (planMessage.code == "000000") {
        this.planList = planMessage.data;
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/02/09 15:25:20
     * @Description 过滤查询线路
     */
    filterLine(filter) {
      if (filter) {
        this.filterLineList = this.lineList.filter(line => {
          if (this.filterForm.lineName && !this.filterForm.bureauId) {
            if (line.lineName.toUpperCase().indexOf(this.filterForm.lineName) !== -1) {
              return line;
            }
          } else if (!this.filterForm.lineName && this.filterForm.bureauId) {
            if (line.bureauId === this.filterForm.bureauId) {
              return line;
            }
          } else if (this.filterForm.lineName && this.filterForm.bureauId) {
            if (line.lineName.toUpperCase().indexOf(this.filterForm.lineName) !== -1 && line.bureauId === this.filterForm.bureauId) {
              return line;
            }
          }
        });

        if (!this.filterLineList || this.filterLineList.length == 0) {
          this.$confirm("当前筛选条件没有匹配到合适的线路, 是否重新筛选?", "提示", {
            confirmButtonText: "重新筛选",
            cancelButtonText: "取消筛选",
            type: "warning"
          })
            .then(() => {})
            .catch(() => {
              this.filterLineList = this.lineList;
              this.filterLineDialogVisible = false;
            });
          return;
        }
      } else {
        this.filterLineList = this.lineList;
      }
      this.filterLineDialogVisible = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:01:41
     * @Description 打开添加线路对话框
     */
    addLineBtn() {
      this.lineDialogVisible = true;
      this.isAddLine = true;
      this.getProvinceData();
    },

    /**
     * @Author KR0288
     * @Date 2022/04/25 17:29:07
     * @Description 添加线路或初始化时获取所有省的信息
     */
    getProvinceData(){
      getProvinces().then(res => {
        if(res.code == "000000") {
          this.provinceList = res.data
        }else {
          this.$message({
            type: "error",
            message: "获取省列表失败"
          })
        }
      }).catch(err => {
          this.$message({
            type: "error",
            message: err
          })
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:35:17
     * @Description 获取当前用户供电局子供电局，作为新增线路下拉选框数据
     */
    getAllNextBureausList() {
      getBureauList().then(res => {
        console.log(res.data);
        this.bureauList = res.data;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/24 16:03:44
     * @Description 处理改变省
     */
    handleChangeProvince(provinceId) {
      this.remoteLineForm.cityId = "";
      this.remoteLineForm.countyId = "";
      this.countyList = [];
      getCities({ provinceId }).then(res => {
        this.cityList = res.data;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/24 16:07:57
     * @Description 处理改变市
     */
    handleChangeCity(cityId) {
      this.remoteLineForm.countyId = "";
      getCounties({ cityId }).then(res => {
        this.countyList = res.data;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/09 17:01:31
     * @Description 线路状态选择框变换
     */
    groupChange(status) {
      if(status == "正常运行") {
        this.lineForm.lineStatus = true;
      } else {
        this.lineForm.lineStatus = false;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:17:02
     * @Description 确定新增
     */
    confirmAdd() {
      this.$refs.lineForm.validate(valid => {
        if (valid) {
          if(this.lineForm.cityId !== '') {
            let params = this.lineForm;
            addLine(params).then(res => {
              if (res.code === "000000") {
                this.$message({
                  message: "新增巡检线路成功",
                  type: "success"
                });
                this.lineDialogVisible = false;
                this.getAllLineList();
              } else {
                this.$message({
                  message: res.mesg,
                  type: "error"
                });
              }
            });            
          } else {
            this.$message.error('必须填写省市');
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:18:53
     * @Description 删除线路
     */
    deleteLineBtn() {
      this.$confirm("删除线路会将对应的塔杆、巡检计划、充电点等关联信息删除!是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteLine({ id: this.lineForm.id, lineId: this.lineForm.lineId }).then(res => {
            if (res.code === "000000") {
              this.$message({
                message: res.data,
                type: "success"
              });
              this.lineDialogVisible = false;
              this.getAllLineList();
            } else {
              this.$message({
                message: res.data,
                type: "error"
              });
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 10:40:55
     * @Description 修改线路
     */
    updateLineBtn() {
      this.$refs.lineForm.validate(valid => {
        if (valid) {
          if(this.lineForm.cityId !== '') {
            let params = this.lineForm;
            updateLine(params).then(res => {
              if (res.code === "000000") {
                this.$message({
                  message: "修改巡检线路成功",
                  type: "success"
                });

                // 修改城市天气
                // this.updateWeather();

                this.lineDialogVisible = false;
                this.getAllLineList();
              } else {
                this.$message({
                  message: res.mesg,
                  type: "error"
                });
              }
            });            
          } else {
            this.$message.error('必须填写省市');
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/23 15:19:12
     * @Description 更新天气
     */
    updateWeather() {
      let params = {
        lineId: this.lineForm.lineId,
        // isPushCityWeather: this.lineForm.isPushCityWeather,
        provinceId: this.lineForm.provinceId,
        cityId: this.lineForm.cityId,
        countyId: this.lineForm.countyId,
      }

      let region = "";
      if(this.provinceList.length>0) {
        region = this.provinceList.find(p => {return p.provinceId.indexOf(this.lineForm.provinceId)>-1});
        params.provinceName = region ? region.provinceName : "";
      }

      if(this.cityList.length>0) {
         region = this.cityList.find(c => {return this.lineForm.cityId.indexOf(c.cityId)>-1});
         params.cityName = region ? region.cityName : "";
      }

      if(this.countyList.length>0) {
        region = this.countyList.find(c => {return this.lineForm.countyId.indexOf(c.countyId)>-1});
        params.countyName = region ? region.countyName : "";
      }

      if(!params.provinceId) {
        this.$alert('必须定位当前城市所在位置！', '配置失败', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `取消更新推送配置！`
            });
          }
        });
        return;
      }

      if(params.provinceName.indexOf("市")==-1 && params.cityName=="" && params.countyName=="") {
        this.$alert('获取城市的天气数据必须精确到市或区的行政区域！', '配置失败', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `取消更新推送配置！`
            });
          }
        });
        return;
      }
      
      let locateCity = !params.countyName ? !params.cityName ? params.provinceName : params.cityName : params.countyName; 
      params.websocketUser = this.visitorId;
      params.locateCity = locateCity;

      updateSubscribeCity(params).then(res => {
         if(res.code==="000000") {
          this.$store.commit("updateCity", locateCity)
          this.$message.success(res.data);
         }else {
           this.$message.warning(res.data);
         }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:14:59
     * @Description 关闭添加线路弹窗
     */
    closeAdd(){
       this.lineDialogVisible=false;
       this.isAddLine = false;
       this.$refs.lineForm.resetFields();
       this.selectStatus = '正常运行';
    },


    /**
     * @Author KR0132
     * @Date 2022/02/24 15:28:27
     * @Description 打开同步线路对话窗口
     */
    synchronizeLine() {
      this.synchronizeLineDialogVisible = true;
      // getBureauRegion().then(res => {
      //   console.log(res);
      //   this.provinceList = res.data.provinceIdList;
      //   this.cityList = res.data.cityIdList;
      //   this.countyList = res.data.countyIdList;
      // });
      this.getProvinceData()
      this.cityList = [];
      this.countyList = [];
    },


    /**
     * @Author KR0132
     * @Date 2022/02/24 16:31:24
     * @Description 向汇编系统查询线路
     */
    handleGetLineFromRemote() {
      this.$refs["remoteLineFormRef"].validate(valid => {
        if (valid) {
          this.isLoadingLine = true;
          getLineFromDataSystem(this.remoteLineForm).then(res => {
            this.isLoadingLine = false;
            this.syncLineList = res.data.filter(d => {
              if (this.remoteLineForm.key) {
                return d.lineName.indexOf(this.remoteLineForm.key) > -1;
              } else {
                return true;
              }
            });
          });
        }
      });
    },


    /**
     * @Author KR0132
     * @Date 2022/02/25 16:25:33
     * @Description 触发展开或关闭同步线路表格
     */
    handleLineRowExpand(row, expandedRows) {
      if (this.lastRow && expandedRows.length > 1) {
        this.$refs["syncLineTableRef"].toggleRowExpansion(this.lastRow, false);
      }
      this.lastRow = row;
    },


    /**
     * @Author KR0132
     * @Date 2022/02/24 15:12:47
     * @Description 打开和汇编系统同步线路对话框
     */
    handleSyncLine(row) {
      this.syncLineForm.lineId = row.id;
      this.beginSyncLineDialogVisible = true;
      getBureasByRegion({ 
        provinceId: row.provinceId, 
        cityId: row.cityId, 
        countyId: row.countyId 
      }).then(res => {
        if(res.data.length==0) {
          let location = "尚未创建此地区："+  row.province + row.city + row.county + "的公司，无法同步此线路！";
          this.$alert(location, '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.beginSyncLineDialogVisible = false;
            this.$message({
              type: 'error',
              message: `同步线路${row.lineName}失败`
            });
          }
        });
         
        }
        this.syncBureauList = res.data;
      });
    },


    /**
     * @Author KR0132
     * @Date 2022/02/25 18:41:40
     * @Description 立即开始同步线路
     */
    beginSyncLine() {
      SyncLineFromDataSystem(this.syncLineForm).then(res => {
        if (res.code === "000000") {
          this.$message({ message: res.data, type: "success" });
          this.beginSyncLineDialogVisible = false;
          this.getAllLineList();
        } else {
          this.$message({ message: res.data, type: "warning" });
        }
      });
    },

       /**
     * @Author KR0132
     * @Date 2022/02/28 10:05:46
     * @Description 处理同步线路关闭弹窗事件
     */
    handleClose(formRef) {
      this.$refs[formRef].resetFields();
      this.provinceList = [];
      this.cityList = [];
      this.countyList = [];
      this.syncLineList = [];
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:27:00
     * @Description 跳转到塔杆详情页
     */
    goToTowerList(line) {
      console.log(this.$router);
      this.$router.push({
        path: "towerListDetails",
        query: {
          lineId: line.lineId
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/04/25 16:07:59
     * @Description 编辑线路主体信息
     */
    handleEdit(line) {
      this.isAddLine = false;
      this.lineDialogVisible = true;
      getLineById({ id: line.id }).then(res => {
 
        // 1、从服务器查询该线路信息，填充到表单上
        console.log(res.data);
        if(res.data.provinceId === "0") {
          res.data.provinceId = "";
          res.data.provinceName = null;
        }
        if(res.data.cityId === "0") {
          res.data.cityId = "";
          res.data.cityName = null;
        }
        if(res.data.countyId === "0") {
          res.data.countyId = "";
          res.data.countyName = null;
        }
        this.lineForm = res.data;

        // 2、如果线路本身就有省了，就要获取该省的所有市
        if(res.data.provinceId === 0) {
          getProvinces().then(res => {
            this.provinceList = res.data;
          })
        } else {
          getCities({ provinceId: res.data.provinceId }).then(res => {
            this.cityList = res.data;
          });
        }

        // 3、如果本身有市了就获取区
        if(res.data.cityId != 0) {
          getCounties({ cityId: res.data.cityId }).then(res => {
            this.countyList = res.data;
          });
        } 

        if (this.lineForm.lineStatus) {
          this.selectStatus = "正常运行";
        } else {
          this.selectStatus = "禁用";
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/03/21 09:49:08
     * @Description 查看查询气象基站详情
     */
    handleCheckWeatherStation() {
      this.weatherDialogVisible = true;
      this.weatherStationDataList = [];
       this.$nextTick(() => {
        let params = {
          websocketUser: this.visitorId, 
          lineId: this.lineId
        };
        getSubscribingWeathers(params).then(res => {
          this.weatherStationDataList = [];
          if(res.code==="000000") {
            if(res.data.stationList.length===0) {
              this.$message.warning("当前线路暂无气象基站数据！");
            }
            for(let index in res.data.stationList) {
              this.weatherStationDataList.push( res.data.stationList[index]);
            }
          }else {
            this.$message.warning(res.data);
          }
        }) 
      })
    },

    /**
     * @Author KR0132
     * @Date 2022/03/21 10:13:29
     * @Description 打开配置该线路所监听的气象基站列表的对话框
     */
    updateOpenWeatherStationDialog(){
      this.weatherConfigDialogVisible = true;
      this.listenLineForm.lineId = this.filterLineList[this.lineIndex].lineId;
      this.handleChangeLine();
    },

    /**
     * @Author KR0132
     * @Date 2022/03/21 10:38:28
     * @Description 更新该线路所监听的气象基站列表
     */
    updateConfigWeatherMessage() {
      
      this.listenLineForm.weatherStations = this.listenLineForm.weatherStations ? this.listenLineForm.weatherStations : [];
      let subscribeStations = this.selectWeatherStation.filter(s => {
        return this.listenLineForm.weatherStations.findIndex(w =>s.weatherStationName===w)>-1;
      }).map(s => {return s.weatherStationMac;});

      let unsubscribeStations = this.selectWeatherStation.filter(s => {
        return this.listenLineForm.weatherStations.findIndex(w =>s.weatherStationName===w)==-1;
      }).map(s => {return s.weatherStationMac;});

      let params = {websocketUser: this.visitorId, lineId: this.listenLineForm.lineId, subscribeWeatherStations: subscribeStations, unsubscribeWeatherStations: unsubscribeStations};

      updateSubscribeWeatherStations(params).then(res => {
        if(res.code==="000000") {

          /*
           * @Date 2022/03/23 15:26:18
           * 更新监听气象基站列表
           */
          let stationList = this.selectWeatherStation.filter(s => {
              return this.listenLineForm.weatherStations.findIndex(w =>s.weatherStationName===w)>-1;
          });
          this.$store.commit("updateWeatherStationList", stationList)

          this.$message.success(res.data);
          this.handleCheckWeatherStation();
          this.weatherConfigDialogVisible = false;
        }else {
           this.$message.warning(res.data);
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:39:17
     * @Description 关闭配置该线路所监听的气象基站列表的对话框
     */
    closeWeatherConfigDialog(){
      this.weatherConfigDialogVisible =false;
      this.$refs.listenLineFormRef.resetFields();
      this.listenLineForm.weatherStations=[];
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:40:31
     * @Description 处理修改气象推送配置中的线路事件
     */
    handleChangeLine() {
      this.$nextTick(() => {
        let params = {
          websocketUser: this.visitorId, 
          lineId: this.listenLineForm.lineId
        };
        getLineWeatherPushConfig(params).then(res => {
          this.listenLineForm.weatherStations = res.data.weatherStations.filter(station => {
            return station.isPush;
          }).map(station => { return station.weatherStationName;});

          this.selectWeatherStation = res.data.weatherStations;
        })
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:40:50
     * @Description 展示视频
     */
    showBigVideo(token) {
      this.centerVideoToken = token;
      this.isShowMiniMap = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/10 14:41:00
     * @Description 展示地图
     */
    showBigMap() {
      this.isShowMiniMap = false;
    },

    // 获取回路数类型
    getLineCircuitTypeList() {
      getCircuitTypeList().then(res => {
        var list = res.data.map(item => {
          return {
            label: item,
            value: item
          };
        });
        this.circuitList = list;
      });
    },

    // 获取地线类型
    getLineWireTypeList() {
      getWireTypeList().then(res => {
        var list = res.data.map(item => {
          return {
            label: item,
            value: item
          };
        });
        this.wireTypeList = list;
        this.leftWireTypeList = list;
        this.rightWireTypeList = list;
      });
    },
  },

  activated() {
    this.getAllLineList();
  }
};
</script>
<style lang="scss" scoped>
.home-page {
  height: calc(100% - 5vh);
  padding: 0 1vw;
  color: #fff;
  .home-log {
    height: 20.5vh;
    ul {
      li {
        list-style: none;
        .log-time {
          color: #e4ff00;
        }
      }
    }
  }
  .top-router {
    padding: 0 1vw;
    position: relative;
  }
  .main {
    height: calc(100% - 5vh);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      width: 13.6vw;
      height: 70vh;
      .top-button {
        margin: 1vh 0 1vh 1.5vh;
      }
      .line-list {
        margin-bottom: 1vh;
        .sub-title {
          padding: 1vh 0;
          font-size: 1.3vh;
          display: flex;
          justify-content: space-around;
          span:first-child {
            width: 7vw;
            text-align: left;
          }
        }
        ul {
          background-color: rgba(0, 0, 0, 0.5);
          height: 29vh;
          padding: 0;
          margin: 0;
          overflow-y: auto;
          li {
            padding: 1vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            span {
              width: 2vw;
              text-align: center;
            }
            span:first-child {
              width: 7vw;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .edit-class {
              cursor: pointer;
            }
          }
        }
      }
      .img-warn {
        justify-content: space-between !important;
        .el-button {
          padding: 1vh;
          .el-icon-picture-outline {
            font-size: 1.4vh !important;
          }
        }
      }
      .img-warn-list {
        background-color: rgba(0, 0, 0, 0.5);
        .warn-class {
          height: 20.5vh;
          width: 13vw;
          margin: auto;
          margin-bottom: 5px;
          margin-top: 5px;
          .seamless-warp {
            overflow: hidden;
            height: 42vh;
          }
        }
        .weather-class {
          margin: auto;
          margin-bottom: 5px;
          margin-top: 5px;
          height: 23.5vh;
          width: 13vw;
        }
        .img-class {
          .seamless-warp {
            overflow: hidden;
            height: 37vh;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            padding: 1vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
    .right {
      width: 13.6vw;
      height: 88vh;
      background-color: rgba(0, 0, 0, 0.5);
      .baseInfo-ctrl {
        justify-content: space-between !important;
        .el-button {
          padding: 1vh;
          .el-icon-picture-outline {
            font-size: 1.4vh !important;
          }
        }
      }
      .baseInfo {
        padding: 1vh 0.5vh;
        font-size: 1.3vh;

        .plan-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sub-title {
          padding-bottom: 0.5vh;
        }
        .info-class {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .msg-item {
            width: 50%;
            height: 10vh;
            // border: 1px solid red;
            box-sizing: border-box;
            background: url("~@/assets/imgs/green/roundBg.png") no-repeat;
            background-size: 50%;
            background-position: 49% 86%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .msg-name {
              width: 4vw;
              position: absolute;
              top: 0.4vh;
              left: 30%;
            }
            .name-type {
              width: 5vw;
              position: absolute;
              bottom: 0.4vh;
              left: 30%;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行 （只显示一行）
            }
            .robot-machine {
              width: 60%;
              height: 60%;
              background: url("~@/assets/imgs/green/machine.png") no-repeat;
              background-size: 50%;
              background-position: 50% 5%;
            }
            .line-type {
              width: 60%;
              height: 60%;
              background: url("~@/assets/imgs/green/line.png") no-repeat;
              background-size: 40%;
              background-position: 50% 5%;
            }
          }
        }
        .task-list {
          // margin-bottom: .0391rem;
          .title {
            padding: 0;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-around;
          }
          ul {
            margin: 0;
            padding: 0;
            height: 10vh;
            overflow-y: auto;
            li {
              display: flex;
              justify-content: space-between;
              span {
                width: 33.33%;
                text-align: center;
              }
            }
          }
        }
      }

      .img-class {
        .seamless-warp {
          overflow: hidden;
          height: 45vh;
          ul {
            padding: 0;
            margin: 0;
            li {
              padding: 1vh 0.5vh;
              list-style: none;
              font-size: 1.3vh;
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }
    .center {
      width: 69vw;
      height: 86vh;
      padding-top: 2vh;
      .bottom-liveplayer {
        position: absolute;
        bottom: 3vh;
        right: 16vw;
        display: flex;
        .bottom-video {
          height: 16vh;
          width: 13vw;
          border: 1px solid #0aaf8860;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .line-form-class {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      margin: auto;
      width: 80%;
      .voltage-level::after {
        content: "KV";
        color: #ccc;
        position: absolute;
        top: 0;
        right: 0.5vw;
      }
      .line-length::after {
        content: "KM";
        color: #ccc;
        position: absolute;
        top: 0;
        right: 0.5vw;
      }
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100% !important;
      }
    }
  }
}
.li-actived {
  color: #e4ff00;
  border-left: 2px solid #e4ff00;
  color: #e4ff00;
  background: linear-gradient(90deg, rgba(228, 255, 0, 0.5), rgba(228, 255, 0, 0));
}

/deep/ .my-dialog {
  .el-dialog {
    width: 40vw !important;

    .position {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .province-class,
      .city-class,
      .county-class {
        // flex: 1;
        width: 30% !important;
      }
    }

    .el-form-item__content {
      display: flex;
      align-items: center;
      // line-height: 40px;
    }

    .el-radio {
      line-height: 40px;
    }
  }
}
</style>

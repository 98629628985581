<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-09-15 10:49:46
 * @FilePath: \22-04-13__2002181_OTLRWS_FRONTED_refactoring\src\components\common\weatherFullView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="weather-full-view">
    <el-descriptions class="margin-top"  :column="1" size="normal" border>
      <el-descriptions-item :label="weather.deviceName" v-for="(weather, index) in dynamicWeatherStations" :key="index">
    <el-tooltip class="item" effect="dark" placement="top" >
      <div style="color: #FFF; display: flex; flex-direction: row; justify-content: space-between">
        <div><i class="iconfont icon-wendu weather-icon"/>{{ (weather.temperature ? weather.temperature : "--")}}</div>
        <div><i class="iconfont icon-shidu weather-icon" />{{ (weather.humidity ? weather.humidity : "--")}}</div>
        <div><i class="iconfont icon-qiya weather-icon"/>{{ (weather.pressure ?  weather.pressure : "--")}}</div>
        <div><i class="iconfont icon-fengli weather-icon" />{{ (weather.windSpeed ? weather.windSpeed: "--")}}</div>
        <div><i class="iconfont icon-fengxiang weather-icon" />{{ (weather.windDirection ? weather.windDirection : "--")}}</div>
        <div><i class="iconfont icon-jiangyuliang weather-icon" />{{ (weather.rainFall ? weather.rainFall : "--" )}}</div>
        <div><i class="iconfont icon-guangzhao weather-icon" />{{ (weather.light ? weather.light: "--")}}</div>
      </div>
      <div slot="content">
        <span>{{weather.deviceName}}</span>
        <ul>
        <li>气温： {{(weather.temperature ? weather.temperature : "--")}}</li>
        <li>空气湿度： {{(weather.humidity ? weather.humidity : "--")}}</li>
        <li>大气压强： {{(weather.pressure ? weather.pressure : "--")}}</li>
        <li>风力强度： {{(weather.windSpeed ? weather.windSpeed : "--")}}</li>
        <li>风向： {{(weather.windDirection ? weather.windDirection : "--")}}</li>
        <li>十分钟雨量： {{(weather.rainFall ? weather.rainFall : "--")}}</li>
        <li>光照强度： {{(weather.light ? weather.light : "--")}}</li>
        </ul></div>
    </el-tooltip>
     </el-descriptions-item>
     </el-descriptions>

   
  </div>
</template>
<script>
import { mapState } from "vuex";
import {parseWindDirection, parseWindLevel} from "@/utils/js/formatterWeather.js"
export default {
  name: "weatherFullView",

  data() {
    return {
      
    }
  },
  computed: {
    ...mapState(["weatherStationList", "weatherStationDataList"]),

    dynamicWeatherStations: function() {
      if(!this.weatherStationList || this.weatherStationList.length==0) {
        return [];
      }
      return this.weatherStationList.map(s => {
        let data = this.weatherStationDataList.find(d => {return s.weatherStationMac.indexOf(d.deviceMac)>-1});
         return data ? data : {deviceName: s.weatherStationName, deviceMac: s.weatherStationMac};
      });
    }
  },

  methods: {

    /*
     * @Date 2022/03/23 14:37:34
     * 转换风速
     */
    convertWindSpeed(windSpeed) {
      return parseWindLevel(windSpeed);
    },

    /*
     * @Date 2022/03/23 14:38:22
     * 转换风向
     */
    convertWindDirection(windDirection) {
      return parseWindDirection(windDirection);
    }

  },
  
};
</script>

<style scoped lang="scss">
.weather-full-view {
  max-height: 50vh;
  overflow-y: auto;
}


</style>
